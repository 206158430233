@import url("https://fonts.googleapis.com/css?family=Montserrat:700,500,600,800");

.container-center-horizontal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    width: 100%;
    background-color: var(--white);
}

.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}

* {
    box-sizing: border-box;
}

:root {
    --anzac: #dfb952;
    --azure-radiance: #0076ff;
    --black: #000000;
    --dove-gray: #707070;
    --gallery: #efefef;
    --mercury: #e7e7e7;
    --light--grey: #e7e7e7;
    --sonic-silver: #757575;
    --white: #ffffff;

    --font-size-l: 16px;
    --font-size-m: 14px;
    --font-size-s: 12px;
    --font-size-xl: 24px;
    --font-size-xxl: 28px;
    --font-size-xxxl: 32px;
    --font-size-xxxxl: 52px;

    --font-family-montserrat: "Montserrat", Helvetica;
}

.montserrat-extra-bold-black-24px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 800;
}

.montserrat-semi-bold-dove-gray-14px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 600;
}

.montserrat-bold-white-16px {
    color: var(--white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 700;
}

.montserrat-bold-white-32px {
    color: var(--white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.montserrat-medium-dove-gray-28px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxl);
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-dove-gray-16px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-l);
    font-style: normal;
    font-weight: 500;
}

.montserrat-semi-bold-dove-gray-12px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 600;
}

.montserrat-extra-bold-black-38px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 38px;
    font-style: normal;
    font-weight: 800;
}

.montserrat-bold-white-24px {
    color: var(--white);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 700;
}

.montserrat-semi-bold-dove-gray-24px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 600;
}

.montserrat-bold-azure-radiance-32px {
    color: var(--azure-radiance);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 700;
}

.montserrat-semi-bold-dove-gray-32px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxl);
    font-style: normal;
    font-weight: 600;
}

.montserrat-extra-bold-black-39px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: 39px;
    font-style: normal;
    font-weight: 800;
}

.montserrat-medium-dove-gray-21px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-medium-dove-gray-17px {
    color: var(--dove-gray);
    font-family: var(--font-family-montserrat);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
}

.montserrat-semi-bold-sonic-silver-10px {
    color: var(--sonic-silver);
    font-family: var(--font-family-montserrat);
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
}

.montserrat-bold-black-14px {
    color: var(--black);
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 700;
}

.border-1px-mercury {
    border: 1px solid var(--mercury);
}

.border-4px-white {
    border: 4px solid var(--white);
}

.border-4px-mercury {
    border: 4px solid var(--mercury);
}

:root {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 62.5% !important;
    --toastify-toast-min-height: 4rem !important;
}

.Toastify__toast-body {
    padding: 0 0 0 1rem !important;
}

* {
    scroll-behavior: smooth;
}

*:focus {
    outline: none !important;
}

body {
    padding: 0;
    margin: 0;
}

b {
    font-weight: 500;
}

/* 
 * This has been added to override popover
 * adding these properties
 * */
.Toastify__toast {
    font-size: 1.4rem !important;
    border-radius: 10px !important;
    margin: 2rem 1rem 0 1rem !important;
}

.Toastify__toast-container {
    bottom: 1rem !important;
}

iframe {
    color-scheme: normal;
}

a,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

main {
    display: flex;
}

iframe {
    color-scheme: auto;
}

.fontBold {
    font-weight: 500 !important;
}

.fontGrey {
    color: #757575 !important;
}

.fontWhite {
    color: #fff !important;
}

.flex {
    display: flex;
}

.alignItemsCenter {
    align-items: center;
}

.alignSelfStart {
    align-self: start;
}

.overflow {
    overflow: hidden;
}

.topPositioned {
    top: 0 !important;
}

.topPositionedLiveCarousel {
    top: 22px !important;
}

.leftPositioned {
    left: 0 !important;
}

.rightPositioned {
    right: 0 !important;
}

.bottom30 {
    bottom: 30px !important;
}

.cursorPointer {
    cursor: pointer !important;
}

.cursorDefault {
    cursor: default !important;
}

.positionRelative {
    position: relative;
}

.absolutePositioned {
    position: absolute !important;
}

.zIndexOne {
    z-index: 1;
}

.zIndexTwo {
    z-index: 2;
}

.zIndexTen {
    z-index: 10;
}

.noLineHeight {
    line-height: 0 !important;
}

.flexOne {
    flex: 1 !important;
}

.topPositioned {
    top: 0 !important;
}

.bottomPositioned {
    bottom: 0 !important;
}

.squareAspectRatio {
    padding-top: 100% !important;
}

.rectangleAspectRatio {
    padding-top: 56.25% !important;
}

.justifyContentCenter {
    justify-content: center !important;
}

.leftSpacing {
    margin-left: 1rem !important;
}

.ml-none {
    margin-left: unset !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.textTransformNone {
    text-transform: none !important;
}

.fullWidth {
    width: 100% !important;
}

.topSpacing {
    margin-top: 1rem !important;
}

.topPadding {
    padding-top: 1rem !important;
}

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #126bd5;

    position: fixed;
    z-index: 1531;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
}

.MuiDrawer-paper {
    width: 20% !important;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

.toastr {
    min-height: 0px !important;
}

.rrt-success {
    background-color: #4caf50 !important;
}

.rrt-info {
    background-color: #2196f3 !important;
}

.rrt-error {
    background-color: #f44336 !important;
}

.toastr-icon {
    width: 25px !important;
    height: 25px !important;
}

.close-toastr {
    font-size: 15px !important;
}

.bottomSpacing {
    margin-bottom: 1rem !important;
}

.spaceBetween {
    justify-content: space-between !important;
}

.flexEnd {
    justify-content: flex-end !important;
}

.rightSpacing {
    margin-right: 1rem !important;
}

.spaceEvenly {
    justify-content: space-evenly !important;
}

.fontNormal {
    font-weight: normal !important;
}

.anchorText {
    text-decoration: none !important;
    color: inherit !important;
}

.parentHeight {
    height: 100% !important;
}

.parentWidth {
    width: 100% !important;
}

.parentHeightWidth {
    height: 100% !important;
    width: 100% !important;
}

.flexWrap {
    flex-wrap: wrap !important;
}

.noPointerEvents {
    pointer-events: none !important;
    opacity: 0.4 !important;
}

.noDisplay {
    display: none !important;
}

.whiteText {
    color: #fff !important;
}

.primaryColor {
    color: #126bd5 !important;
}

.textOverflowEllipsis {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.textAlignCenter {
    text-align: center !important;
}

.textAlignRight {
    text-align: right !important;
}

.textAlignLeft {
    text-align: left !important;
}

.flexDirectionColumn {
    flex-direction: column !important;
}

.staticContainer {
    padding: 10px;
    margin-bottom: 48px;
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

.smallFont {
    font-size: 13px;
}

.MuiAppBar-root {
    z-index: 0 !important;
}

.MuiMenu-paper {
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.12) !important;
}

.linkify {
    color: #126bd5 !important;
}

@keyframes marquee {
    from {
        margin-left: 120%;
    }

    to {
        margin-left: -20%;
    }
}

.colorWhite {
    color: white !important;
}

.colorGrey {
    color: #757575 !important;
}

.colorBlack {
    color: black !important;
}

.marquee-container {
    overflow: hidden;
}

.marquee {
    min-width: 100%;
    animation: marquee 15s linear infinite;
}

.marquee:hover {
    animation-play-state: paused;
}

.textCenter {
    text-align: center;
}

.dropzone {
    border: 2px dashed rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 50px 0;
    text-align: center;
    text-transform: uppercase;
    color: red;
}

.dropzone.active {
    border-color: #fff;
}

.file {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    background-color: #41415d;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    position: relative;
    overflow: hidden;
}

.file .progress {
    background-color: rgba(80, 80, 120, 0.8);
    position: absolute;
    inset: 0;
    width: 0;
    padding-top: 10px;
    text-align: center;
}

.file .progress.done {
    top: 100px;
}

.hideScrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.hideScrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.wordpress-blog-image img {
    width: 100%;
    height: fit-content;
}

/* .rooter-typography {
    font-size: calc(0.75rem + 0.5vw);
} */

/* @media (max-width: 600px) {
    .rooter-typography {
        font-size: 1.6rem;
    }
} */
.animationProBorder:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 140px;
    width: 140px;
    background-image: conic-gradient(#00DBDE00 10%, #00dbde 20%, #6a8fef 30%, #6A8FEF00 40%, #6A8FEF00 60%, #00dbde 70%, #6a8fef 80%, #00DBDE00 90%);
    z-index: 0;
    transform: translate(-50%, -50%);
    animation: rotateProChip 6s linear infinite;
}

@keyframes rotateProChip {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.not-found-page {
    background-color: #121212;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 20px;
    margin: 0;
}

.link {
    color: blue;
    text-decoration: underline;
}
.x241-0-mweb-download-page-4 {
	align-items: flex-start;
	background-color: var(--white);
	display: flex;
	width: 100%;
	margin-bottom: 75px;
}

.group-9812-2 {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin-top: 75px;
	width: 100%;
	z-index: 1;
}

.group-9759-5 {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.overlap-group2-5 {
	height: 120vw;
	position: relative;
	width: 100%;
}

.x0-26,
.x2-5,
.x3-2,
.x4-2,
.x5-2,
.x6-2 {
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.rooter-logo {
	height: 28px;
	position: absolute;
	top: 102vw;
	left: 50%;
    transform: translateX(-50%);
}

.rooter-watch-gaming-esports-6 {
	height: 22px;
	letter-spacing: 0;
	line-height: 16px;
	position: absolute;
	text-align: center;
	top: 113vw;
	width: 100%;
}

.join-the-rooter-squad-5 {
	letter-spacing: 0;
	line-height: 102px;
	position: absolute;
	top: 112vw;
	white-space: nowrap;
	width: 100%;
	text-align: center;
}

.win-free-fire-diamon-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-left: 2.0px;
	margin-top: 48px;
	min-height: 41px;
	min-width: 258px;
	text-align: center;
	white-space: nowrap;
}

.overlap-group-27 {
	height: 49px;
	margin-left: 1.0px;
	margin-top: 50px;
	position: relative;
	width: 215px;
}

.group-9287-6 {
	display: flex;
	height: 49px;
	left: 0;
	position: absolute;
	top: 0;
	width: 215px;
}

.group-9287-7 {
	display: flex;
	height: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	width: 100%;
}


.group-9-6 {
	display: flex;
	flex: 1;
	width: 215px;
}

.group-9-6-2 {
	display: flex;
	flex: 1;
	width: 215px;
	overflow: hidden;
	border-radius: 12px;
}

.subtraction-134-6 {
	flex: 1;
	margin-bottom: -0.5px;
	margin-left: -0.5px;
	margin-right: -0.5px;
	margin-top: -0.5px;
	object-fit: cover;
	width: 216px;
}

.subtraction-134-7 {
	width: 100%;
	height: 96px;
    overflow: hidden;
    margin-top: -20px;
}

.download-app-11 {
	left: 45px;
	letter-spacing: 0;
	line-height: 16px;
	position: absolute;
	top: 17px;
	white-space: nowrap;
}

.download-app-12 {
	left: 50%;
	letter-spacing: 0;
	line-height: 16px;
	position: absolute;
	transform: translate(-50%,-50%);
	top: 50%;
	white-space: nowrap;
}

.group-9807-2 {
	display: flex;
	margin-top: 30px;
	width: 100%;
}

.group-9759-6 {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
}

.overlap-group1-3 {
	height: 70vw;
	position: relative;
	width: 100%;
}

.group-9808-4 {
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.godl-ike-e-sports-5 {
	letter-spacing: 0;
	line-height: 102px;
	position: absolute;
	text-align: center;
	top: 60vw;
	white-space: nowrap;
	width: 100%;
}

.streaming-exclusively-on-rooter-5 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 50px;
	text-align: center;
	white-space: nowrap;
}

.group-9811-2 {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.group-9809-2 {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.overlap-group-28 {
	height: 110vw;
	position: relative;
	width: 100%;
}

.enjoy-all-skyesports-tournaments-6,
.gaming-nights-with-yuzi-riyan-6 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.skyesports-streaming-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 50px;
	text-align: center;
	white-space: nowrap;
}

.group-981 {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.overlap-group-29 {
	height: 110vw;
	position: relative;
	width: 100%;
}

.top-e-sports-teams-5 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.top-e-sports-teams-st-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 15px;
	text-align: center;
	white-space: nowrap;
}

.group-9813-2 {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.watch-play-chat-with-them-live-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 50px;
	text-align: center;
	white-space: nowrap;
}

.overlap-group-30 {
	height: 49px;
	margin-right: 1.0px;
	margin-top: 29px;
	position: relative;
	width: 215px;
}

.watch-win-rewards-4 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.win-free-f-ire-diamon-4 {
	letter-spacing: 0;
	line-height: 21px;
	margin-left: 2.0px;
	margin-top: 15px;
	min-height: 41px;
	min-width: 260px;
	text-align: center;
	white-space: nowrap;
}

.group-9815-2 {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.live-streams-5 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.enjoy-the-best-quality-streams-in-hd-5 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 15px;
	text-align: center;
	white-space: nowrap;
}

.group-9816-2 {
	align-items: center;
	justify-content: center;
	background-color: var(--mercury);
	display: flex;
	height: 35px;
	margin-top: 30px;
	margin-bottom: 10px;
	width: 100%;
}

.copyright-6 {
	letter-spacing: 0;
	line-height: 18px;
	min-height: 17px;
	white-space: nowrap;
}

.group-9761-2 {
	align-items: center;
	justify-content: center;
	background-color: var(--white);
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
	height: 75px;
	left: 0;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 2;
}

.group-9761-3 {
	align-items: center;
	justify-content: center;
	background-color: var(--white);
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
	height: 75px;
	padding: 12px;
	left: 0;
	width: 100%;
	bottom: 0;
	z-index: 2;
}


.rooter-logo-8 {
	height: 28px;
}

.download-app-btn {
	width: 100%;
	height: 100%;
	border: none;
	background: none;
}

.download-app-btn-2 {
	width: 100%;
	height: 100%;
	border: none;
	background: none;
	position: relative;
}

.group-9872 {
	align-items: flex-start;
	display: flex;
	margin-top: 30px;
	width: 100%;
}

.group-container {
	height: 50px;
	position: relative;
	width: 100%;
}

.group-9873 {
	align-items: center;
	background-color: var(--light--grey);
	display: flex;
	height: 50px;
	justify-content: space-around;
	padding: 9px 35.5px;
	top: 0;
	width: 100%;
}

.path-53106 {
	align-self: center;
	height: 29px;
	margin-top: 6px;
	object-fit: cover;
	width: 29px;
}

.group-9875 {
	display: flex;
	height: 31px;
}

.group-9868 {
	display: flex;
	flex: 1;
	width: 116px;
}

.group-987 {
	align-items: flex-end;
	display: flex;
	flex: 1;
	height: 31px;
	min-width: 100%;
}

.overlap-group {
	height: 33px;
	margin-bottom: -2px;
	position: relative;
	width: 118px;
}

.win-daily {
	left: 8px;
	letter-spacing: 0;
	line-height: 10px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 101px;
}

.uc-diamonds {
	left: 0;
	letter-spacing: 0;
	line-height: 14px;
	position: absolute;
	text-align: center;
	top: 13px;
	width: 118px;
}

.group-9874 {
	display: flex;
	height: 31px;
	top: 9px;
}

.group-9876 {
	display: flex;
	flex: 1;
	width: 94px;
}

.overlap-group-1 {
	height: 33px;
	margin-bottom: -2px;
	position: relative;
	width: 96px;
}

.users {
	left: 0;
	letter-spacing: 0;
	line-height: 10px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 96px;
}

.address {
	left: 0;
	letter-spacing: 0;
	line-height: 14px;
	position: absolute;
	text-align: center;
	top: 13px;
	width: 96px;
}



