.x241-0-mweb-download-page-4 {
	align-items: flex-start;
	background-color: var(--white);
	display: flex;
	width: 100%;
	margin-bottom: 75px;
}

.group-9812-2 {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin-top: 75px;
	width: 100%;
	z-index: 1;
}

.group-9759-5 {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.overlap-group2-5 {
	height: 120vw;
	position: relative;
	width: 100%;
}

.x0-26,
.x2-5,
.x3-2,
.x4-2,
.x5-2,
.x6-2 {
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.rooter-logo {
	height: 28px;
	position: absolute;
	top: 102vw;
	left: 50%;
    transform: translateX(-50%);
}

.rooter-watch-gaming-esports-6 {
	height: 22px;
	letter-spacing: 0;
	line-height: 16px;
	position: absolute;
	text-align: center;
	top: 113vw;
	width: 100%;
}

.join-the-rooter-squad-5 {
	letter-spacing: 0;
	line-height: 102px;
	position: absolute;
	top: 112vw;
	white-space: nowrap;
	width: 100%;
	text-align: center;
}

.win-free-fire-diamon-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-left: 2.0px;
	margin-top: 48px;
	min-height: 41px;
	min-width: 258px;
	text-align: center;
	white-space: nowrap;
}

.overlap-group-27 {
	height: 49px;
	margin-left: 1.0px;
	margin-top: 50px;
	position: relative;
	width: 215px;
}

.group-9287-6 {
	display: flex;
	height: 49px;
	left: 0;
	position: absolute;
	top: 0;
	width: 215px;
}

.group-9287-7 {
	display: flex;
	height: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	width: 100%;
}


.group-9-6 {
	display: flex;
	flex: 1;
	width: 215px;
}

.group-9-6-2 {
	display: flex;
	flex: 1;
	width: 215px;
	overflow: hidden;
	border-radius: 12px;
}

.subtraction-134-6 {
	flex: 1;
	margin-bottom: -0.5px;
	margin-left: -0.5px;
	margin-right: -0.5px;
	margin-top: -0.5px;
	object-fit: cover;
	width: 216px;
}

.subtraction-134-7 {
	width: 100%;
	height: 96px;
    overflow: hidden;
    margin-top: -20px;
}

.download-app-11 {
	left: 45px;
	letter-spacing: 0;
	line-height: 16px;
	position: absolute;
	top: 17px;
	white-space: nowrap;
}

.download-app-12 {
	left: 50%;
	letter-spacing: 0;
	line-height: 16px;
	position: absolute;
	transform: translate(-50%,-50%);
	top: 50%;
	white-space: nowrap;
}

.group-9807-2 {
	display: flex;
	margin-top: 30px;
	width: 100%;
}

.group-9759-6 {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
}

.overlap-group1-3 {
	height: 70vw;
	position: relative;
	width: 100%;
}

.group-9808-4 {
	left: 0;
	object-fit: cover;
	position: absolute;
	top: 0;
	width: 100%;
}

.godl-ike-e-sports-5 {
	letter-spacing: 0;
	line-height: 102px;
	position: absolute;
	text-align: center;
	top: 60vw;
	white-space: nowrap;
	width: 100%;
}

.streaming-exclusively-on-rooter-5 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 50px;
	text-align: center;
	white-space: nowrap;
}

.group-9811-2 {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.group-9809-2 {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.overlap-group-28 {
	height: 110vw;
	position: relative;
	width: 100%;
}

.enjoy-all-skyesports-tournaments-6,
.gaming-nights-with-yuzi-riyan-6 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.skyesports-streaming-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 50px;
	text-align: center;
	white-space: nowrap;
}

.group-981 {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.overlap-group-29 {
	height: 110vw;
	position: relative;
	width: 100%;
}

.top-e-sports-teams-5 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.top-e-sports-teams-st-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 15px;
	text-align: center;
	white-space: nowrap;
}

.group-9813-2 {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.watch-play-chat-with-them-live-6 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 50px;
	text-align: center;
	white-space: nowrap;
}

.overlap-group-30 {
	height: 49px;
	margin-right: 1.0px;
	margin-top: 29px;
	position: relative;
	width: 215px;
}

.watch-win-rewards-4 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.win-free-f-ire-diamon-4 {
	letter-spacing: 0;
	line-height: 21px;
	margin-left: 2.0px;
	margin-top: 15px;
	min-height: 41px;
	min-width: 260px;
	text-align: center;
	white-space: nowrap;
}

.group-9815-2 {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 100%;
}

.live-streams-5 {
	letter-spacing: 0;
	line-height: 36px;
	position: absolute;
	text-align: center;
	top: 100vw;
	width: 100%;
	white-space: nowrap;
}

.enjoy-the-best-quality-streams-in-hd-5 {
	letter-spacing: 0;
	line-height: 21px;
	margin-top: 15px;
	text-align: center;
	white-space: nowrap;
}

.group-9816-2 {
	align-items: center;
	justify-content: center;
	background-color: var(--mercury);
	display: flex;
	height: 35px;
	margin-top: 30px;
	margin-bottom: 10px;
	width: 100%;
}

.copyright-6 {
	letter-spacing: 0;
	line-height: 18px;
	min-height: 17px;
	white-space: nowrap;
}

.group-9761-2 {
	align-items: center;
	justify-content: center;
	background-color: var(--white);
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
	height: 75px;
	left: 0;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 2;
}

.group-9761-3 {
	align-items: center;
	justify-content: center;
	background-color: var(--white);
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
	height: 75px;
	padding: 12px;
	left: 0;
	width: 100%;
	bottom: 0;
	z-index: 2;
}


.rooter-logo-8 {
	height: 28px;
}

.download-app-btn {
	width: 100%;
	height: 100%;
	border: none;
	background: none;
}

.download-app-btn-2 {
	width: 100%;
	height: 100%;
	border: none;
	background: none;
	position: relative;
}

.group-9872 {
	align-items: flex-start;
	display: flex;
	margin-top: 30px;
	width: 100%;
}

.group-container {
	height: 50px;
	position: relative;
	width: 100%;
}

.group-9873 {
	align-items: center;
	background-color: var(--light--grey);
	display: flex;
	height: 50px;
	justify-content: space-around;
	padding: 9px 35.5px;
	top: 0;
	width: 100%;
}

.path-53106 {
	align-self: center;
	height: 29px;
	margin-top: 6px;
	object-fit: cover;
	width: 29px;
}

.group-9875 {
	display: flex;
	height: 31px;
}

.group-9868 {
	display: flex;
	flex: 1;
	width: 116px;
}

.group-987 {
	align-items: flex-end;
	display: flex;
	flex: 1;
	height: 31px;
	min-width: 100%;
}

.overlap-group {
	height: 33px;
	margin-bottom: -2px;
	position: relative;
	width: 118px;
}

.win-daily {
	left: 8px;
	letter-spacing: 0;
	line-height: 10px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 101px;
}

.uc-diamonds {
	left: 0;
	letter-spacing: 0;
	line-height: 14px;
	position: absolute;
	text-align: center;
	top: 13px;
	width: 118px;
}

.group-9874 {
	display: flex;
	height: 31px;
	top: 9px;
}

.group-9876 {
	display: flex;
	flex: 1;
	width: 94px;
}

.overlap-group-1 {
	height: 33px;
	margin-bottom: -2px;
	position: relative;
	width: 96px;
}

.users {
	left: 0;
	letter-spacing: 0;
	line-height: 10px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 96px;
}

.address {
	left: 0;
	letter-spacing: 0;
	line-height: 14px;
	position: absolute;
	text-align: center;
	top: 13px;
	width: 96px;
}
